<script lang="ts">
import isEqual from 'lodash-es/isEqual'
import { ValidationObserver } from 'vee-validate'
import Vue, { ref, computed, onMounted, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { useAddressForm, useAddresses } from '/~/composables/addresses'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

type AddressDeep = {
  address: {
    fullAddress: string
    address: string
    suburb: string
    postcode: string
    state: string
  }
}

type Form = {
  companyName: string
  default: boolean
  email?: string
  firstName: string
  id: number
  lastName: string
  mobile?: string
  postcode: string
  state: string
  streetAddress: string
  subpremise: string
  suburb: string
  fullAddress: string
}

const defaultData: Form = {
  companyName: '',
  default: false,
  email: '',
  firstName: '',
  id: -1,
  lastName: '',
  mobile: '',
  postcode: '',
  state: '',
  streetAddress: '',
  subpremise: '',
  suburb: '',
  fullAddress: '',
}

export default {
  name: 'cart-address-form',
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseAsidePage,
    BaseMetafield,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    close: {
      type: Function,
      default: null,
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { addCartOrderAddress } = useCart()
    const { isQuickBuyCurrentFlowType } = useCheckout()
    const { addresses, addAddress, updateAddress } = useAddresses()
    const { addressSchema } = useAddressForm()
    const {
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
    } = useAddressForm()
    const { user } = useUser()
    const { isBillPaymentsTemplate } = useProvider()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { states, translate } = useLocalization()
    const { validationObserverRef } = useForm()
    const router = useRouter()
    const route = useRoute()

    const form = ref<Form>({ ...defaultData })
    const disabledSetDefault = ref(false)
    const submitting = ref(false)
    const initialData = ref({})
    const buttonShadow = ref(false)
    const formRef = ref<HTMLDivElement | null>(null)

    const addressValue = computed<AddressDeep>(() => ({
      address: {
        fullAddress: form.value.fullAddress,
        address: form.value.streetAddress,
        suburb: form.value.suburb,
        postcode: form.value.postcode,
        state: form.value.state,
      },
    }))

    const address = computed(() => {
      return (
        (addresses.value || []).find(
          (address) => `${address.id}` === props.id
        ) || {
          ...defaultData,
        }
      )
    })

    const type = computed(() =>
      props.id && address.value.id !== -1 ? 'edit' : 'create'
    )

    const stateOptions = computed(() =>
      states.value.map((state) => ({
        text: state.label,
        value: state.placeId,
      }))
    )

    const selectedStateId = computed(() => {
      const state = states.value.find(
        (state) => state.label === form.value.state
      )

      return state ? state.id : ''
    })

    const disableSaveBtn = computed(
      () =>
        submitting.value ||
        (type.value === 'edit' && isEqual(initialData.value, form.value))
    )

    function onAddressChanged(value: AddressDeep) {
      Object.assign(form.value, value.address)
      form.value.streetAddress = value.address.address
    }

    async function onSubmitForm() {
      submitting.value = true
      backendErrors.value = {}

      const isCreate = type.value === 'create'
      const action = isCreate ? addAddress : updateAddress
      const params = {
        ...form.value,
        mobile: (form.value.mobile || '')
          .replace(/\s/g, '')
          .replace(/^\+61/g, '0'),
        email: form.value.email ?? '',
        state: selectedStateId.value || form.value.state,
      }

      return action(params)
        .then((response) => {
          Vue.notify({
            text: `Address successfully ${isCreate ? 'created' : 'updated'}`,
            type: 'success',
            duration: 1000,
          })

          if (isCreate) {
            addCartOrderAddress({
              address: response as Awaited<ReturnType<typeof addAddress>>,
              type: props.meta.type,
            })
            if (isQuickBuyCurrentFlowType.value) {
              router.push({
                name: 'quick-buy-checkout',
                query: route.query,
              })
            } else {
              router.replace(
                isBillPaymentsTemplate.value
                  ? { name: 'purchase-checkout' }
                  : { hash: '#cart-checkout' }
              )
            }
          } else {
            router.back()
          }
        })
        .catch((exception) => {
          processBackendErrors({
            errors: exception,
          })
        })
        .finally(() => {
          submitting.value = false
        })
    }

    function checkShadow() {
      if (!formRef.value) {
        return false
      }

      const scrollAmount =
        formRef.value.scrollHeight - formRef.value.offsetHeight

      buttonShadow.value = Boolean(
        scrollAmount && scrollAmount !== formRef.value.scrollTop
      )
    }

    function init() {
      const { email, firstName, lastName, mobile } = user.value
      const userDetails =
        type.value === 'create' ? { email, firstName, lastName, mobile } : {}
      const { streetAddress, suburb, state, postcode } = address.value

      const formLocal = {
        ...form.value,
        ...address.value,
        ...userDetails,
      }

      formLocal.fullAddress = Object.values({
        streetAddress,
        suburb,
        state,
        postcode,
      })
        .filter(Boolean)
        .join(' ')

      form.value = formLocal
      disabledSetDefault.value = type.value === 'edit' && form.value.default

      initialData.value = { ...formLocal }
    }

    onBeforeMount(() => {
      init()
    })

    onMounted(() => {
      form.value = { ...initialData.value }
      checkShadow()
    })

    return {
      addCartOrderAddress,
      isQuickBuyCurrentFlowType,
      addresses,
      addAddress,
      updateAddress,
      user,
      isBillPaymentsTemplate,
      countriesOptions,
      postcodeBindings,
      stateBindings,
      suburbBindings,
      ui,
      backendErrors,
      processBackendErrors,
      states,
      validationObserverRef,
      translate,
      addressSchema,
      type,
      onSubmitForm,
      checkShadow,
      form,
      submitting,
      stateOptions,
      disabledSetDefault,
      disableSaveBtn,
      addressValue,
      onAddressChanged,
    }
  },
}
</script>

<template>
  <base-aside-page
    :title="type === 'create' ? 'Add new address' : 'Edit address'"
  >
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="relative flex grow flex-col"
        @submit.prevent="handleSubmit(onSubmitForm)"
      >
        <div ref="form" @scroll="checkShadow">
          <base-input
            v-model="form.state"
            label="State"
            class="hidden"
            required
          />
          <base-input
            v-model="form.companyName"
            :error="backendErrors.companyName"
            label="Company name (if applicable)"
            name="companyName"
            :disabled="submitting"
          />
          <div class="flex">
            <base-input
              v-model="form.firstName"
              :validation="{
                rules: 'required',
                name: 'First name',
              }"
              :disabled="submitting"
              :error="backendErrors.firstName"
              label="First name"
              name="firstName"
              required
              class="mr-[5px]"
            />
            <base-input
              v-model="form.lastName"
              :validation="{
                rules: 'required',
                name: 'Last name',
              }"
              :disabled="submitting"
              :error="backendErrors.lastName"
              label="Last name"
              name="lastName"
              required
              class="ml-[5px]"
            />
          </div>
          <base-input
            ref="email"
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :disabled="submitting"
            :error="backendErrors.email"
            data-vv-as="Email"
            label="Email"
            name="email"
            type="email"
            required
          />
          <base-input
            v-model="form.mobile"
            :validation="{
              rules: 'required|mobile',
              name: 'Mobile',
            }"
            :disabled="submitting"
            :error="backendErrors.mobile"
            type="tel"
            mask="mobile"
            label="Mobile"
            required
            name="mobile"
          />
          <div>
            <base-metafield
              :value="addressValue"
              :schema="addressSchema"
              :backend-errors="backendErrors"
              name-prefix="address"
              @input="onAddressChanged"
            />
          </div>

          <base-checkbox
            v-model="form.default"
            :disabled="submitting || disabledSetDefault"
            class="p-0"
          >
            Set as default
          </base-checkbox>
        </div>

        <div class="relative mt-auto shrink-0 pt-[30px]">
          <base-button
            type="submit"
            :disabled="submitting || disableSaveBtn"
            full-width
          >
            {{ submitting ? 'Saving...' : 'Save' }}
          </base-button>
        </div>
      </form>
    </validation-observer>
  </base-aside-page>
</template>
